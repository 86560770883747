import { merge, omit } from "lodash";
import {
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../../../../../types/aggregations";
import { BaseSchemaDeal } from "../../../../../../types/entities/base";
import {
  AmortizationType,
  ExecutionType,
  FundingShieldStatusType,
  PropertyManagerType,
  RecourseType
} from "../../../../../../types/entities/deal.enums.types";
import { QuoteType } from "../../../../../../types/entities/deal.quote.types";
import { OptionsOf } from "../../../../../../types/entities/enumeration.types";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  LimaDeal,
  LimaDealFields,
  limaChannelType,
  limaDealExceptionApprovedBy,
  limaFloodInsurancePremiumPayment,
  limaLenderIdentifierType,
  limaLoanPayment,
  limaLoanTermPeriodMonthCount,
  limaPaymentDueDay,
  limaWarehouseBank
} from "../../../../../../types/entities/lima";
import { DotNestedKeys } from "../../../../../../types/utils/flatten";
import {
  getFundingShieldWireInsuranceCertAggregationTempate,
  getFundingShieldWireInsuranceCertTempate
} from "../../integrations/property";
import { getBaseSchemaDealTemplate } from "../base-schema";

export const limaDealBaseFieldsToOmit: DotNestedKeys<BaseSchemaDeal>[] = [
  "Channel",
  "LenderIdentifier",
  "LoanTermPeriodMonthCount",
  "PaymentDueDay",
  "LoanPaymentType",
  "FloodInsurancePremiumPaymentType",
  "WarehouseBankType",
  "InterestType",
  "MERSMin",
  "FundingDate",
  "PrincipalBalance",
  "LateFeeAmount",
  "InvestorInterestRate",
  "InterestRateBuydownPercent",
  "OriginationFeePercent",
  "NoteRatePercent",
  "PrepaymentPenaltyType",
  "PrepaymentPenaltyTerm",
  "LoanProductType",
  "FloorPercent",
  "CeilingPercent",
  "MarginPercent",
  "InterestReserveEscrowAmount",
  "ServicerLoanIdentifier",
  "InvestorLoanIdentifier",
  "ServicerAccountIdentifier",
  "SoldDate",
  "ServicingTransferDate",
  "ServicerIdentifier",
  "AssignmentStatus",
  "ExtendedMaturityDate",
  "PaidOffIndicator",
  "DefaultStatus",
  "FinalPrice",
  "InvestorIdentifier",
  "ShortTermRentalIndicator",
  "LockPeriod",
  "WireReleaseDate",
  "aggregations.MonthlyPrincipalAndInterestPaymentAmount",
  "aggregations.TotalInitialFundedAmount",
  "aggregations.MonthlyInterestOnlyPaymentAmount",
  "aggregations.TotalCostBasisAmount",
  "aggregations.FullyDrawnInterestOnlyPayment",
  "aggregations.InitialMonthlyPayment",
  "aggregations.TotalMonthlyEscrowPaymentsAmount",
  "aggregations.InterestRateBuydownAmount",
  "aggregations.OriginationFeesAmount",
  "aggregations.AmortizationStartDate",
  "aggregations.IoPeriodEndDate",
  "aggregations.TotalPitiaAmount",
  "aggregations.TotalLiquidReservesIncludingCashAtClosing",
  "aggregations.MonthsReservesIncludingCashAtClosing",
  "aggregations.MonthsReservesExcludingCashAtClosing",
  "aggregations.TotalPiti",
  "aggregations.TotalMonthlyHoaFeesAmount",
  "aggregations.TotalAsIsLtvPercent",
  "aggregations.TotalLttcRatePercent",
  "aggregations.TotalLtcRatePercent",
  "aggregations.TotalDscr",
  "aggregations.TotalMonthlyPaymentOfTaxesAndInsurance",
  "aggregations.GrossSpreadPercent",
  "aggregations.TotalEscrowBalanceAmount",
  "aggregations.DaysHeld",
  "aggregations.TotalPayoffAmountOnSettlementStatement",
  "aggregations.MonthsReservesOnFullyDrawnPayment",
  "aggregations.TotalClosingCostsAmount",
  "aggregations.AllInSaleExecution",
  "aggregations.TotalConstructionHoldbackAmount"
];

const getDealQuoteTemplate = () => {
  const template: LimaDeal["quote"] = {
    quoteType: "" as QuoteType,
    aggregations: {
      // SHARED GET

      OriginationFeeAmount: thirdPartyFieldTemplate(),
      ProcessingFeeAmount: thirdPartyFieldTemplate(),
      BrokerOriginationFeeAmount: thirdPartyFieldTemplate(),
      ServicingSetupFee: thirdPartyFieldTemplate(),
      LenderFinanceProcessingFee: thirdPartyFieldTemplate(),
      LenderFinanceApplicationFee: thirdPartyFieldTemplate(),
      LimaRateToBorrowerPercent: thirdPartyFieldTemplate(),
      LimaOriginationFeeToBorrowerPercent: thirdPartyFieldTemplate(),
      PricingEngineWarnings: thirdPartyFieldTemplate(),
      BrokerProcessingFeeAmount: thirdPartyFieldTemplate(),
      FeeLLPAs: thirdPartyFieldTemplate(),
      RateLLPAs: thirdPartyFieldTemplate(),
      PricingEngineOverrides: thirdPartyFieldTemplate(),
      LenderFinanceDocumentFee: thirdPartyFieldTemplate(),
      LenderFinanceAppraisalFee: thirdPartyFieldTemplate(),
      ValidationErrors: thirdPartyFieldTemplate(),
      QuoteId: thirdPartyFieldTemplate(),
      AchRequiredIndicator: thirdPartyFieldTemplate(),
      AchExemptionStatus: thirdPartyFieldTemplate(),
      PricingInfo: thirdPartyFieldTemplate(),
      CampaignCode: thirdPartyFieldTemplate(),
      CampaignRateIncentive: thirdPartyFieldTemplate(),
      CampaignFeeIncentive: thirdPartyFieldTemplate(),
      CampaignLeverageIncentive: thirdPartyFieldTemplate(),
      CampaignExpirationDate: thirdPartyFieldTemplate(),
      // RENTAL GET
      LtvEligibility: thirdPartyFieldTemplate(),
      InterestReserveEscrow: thirdPartyFieldTemplate(),
      PrepaymentPenaltyType: thirdPartyFieldTemplate(),
      NoteRatePercent: thirdPartyFieldTemplate(),
      MonthlyPrincipalInterestPaymentAmount: thirdPartyFieldTemplate(),
      MonthlyTaxInsurancePaymentAmount: thirdPartyFieldTemplate(),
      MonthlyHomeownersAssociationAmount: thirdPartyFieldTemplate(),
      TotalMonthlyPITIPaymentAmount: thirdPartyFieldTemplate(),
      DebtServiceCoverageRatioPercent: thirdPartyFieldTemplate(),
      BuyDownFeeAmount: thirdPartyFieldTemplate(),
      BuyUpFeeReductionAmount: thirdPartyFieldTemplate(),
      Margin: thirdPartyFieldTemplate(),
      FloorPercent: thirdPartyFieldTemplate(),
      LifetimeCapPercent: thirdPartyFieldTemplate(),
      DeferredOriginationFeeAmount: thirdPartyFieldTemplate(),

      // RENTAL POST
      scenario: {
        scenario: [
          {
            scenarioId: thirdPartyFieldTemplate()
          }
        ]
      },
      UnderPropertyValuationAmount: aggregationFieldTemplate(),
      UnderPropertyValuationTotalCost: aggregationFieldTemplate(),
      PrimaryBorrowerCreditScore: aggregationFieldTemplate(),
      StateNY: aggregationFieldTemplate(),
      InternalL1CRefinanceIndicator: aggregationFieldTemplate(),
      BorrowingEntity: aggregationFieldTemplate(),
      OverPropertyValuationAmount: aggregationFieldTemplate(),
      TotalValuationOrPurchasePriceAmount: aggregationFieldTemplate(),
      PropertyTypeCondoIndicator: aggregationFieldTemplate(),
      PropertyValuationAmountUnderIndicator: aggregationFieldTemplate(),
      USCitizenshipIndicator: aggregationFieldTemplate(),
      SeasonalRental: aggregationFieldTemplate(),
      PropertyTypeMultifamilyIndicator: aggregationFieldTemplate(),
      // SHORT-TERM GET
      InitialMonthlyInterestOnlyPaymentAmount: thirdPartyFieldTemplate(),
      PricingEngineLTARVRatePercent: thirdPartyFieldTemplate(),
      PricingEngineTotalLoanAmount: thirdPartyFieldTemplate(),
      FullyDrawnInterestOnlyPayment: thirdPartyFieldTemplate(),
      PricingEngineTotalLoanToAfterRepairValueOverridePercent:
        thirdPartyFieldTemplate(),
      PricingEngineTotalBlendedLoanToCostOverridePercent:
        thirdPartyFieldTemplate(),
      PricingEngineTotalPurchaseLoanToCostOverridePercent:
        thirdPartyFieldTemplate(),
      PricingEngineNoteRateOverridePercent: thirdPartyFieldTemplate(),

      PricingEngineMaxLoanToAfterRepairValuePercent: thirdPartyFieldTemplate(),
      PricingEngineMaxBlendedLoanToCostPercent: thirdPartyFieldTemplate(),
      PricingEngineMaxPurchaseLoanToCostPercent: thirdPartyFieldTemplate(),
      BlendedLTCAdjuster: thirdPartyFieldTemplate(),
      PurchaseLTCAdjuster: thirdPartyFieldTemplate(),
      ARVAdjuster: thirdPartyFieldTemplate(),

      // SHORT-TERM POST
      PrimaryGuarantor: aggregationFieldTemplate(),
      CompletedExits: aggregationFieldTemplate(),
      SumTotalStatementQualifyingBalance: aggregationFieldTemplate(),
      AltTier: aggregationFieldTemplate(),
      LesserOfLotOrPurchase: aggregationFieldTemplate(),
      DebtStatus: aggregationFieldTemplate(),
      PropertyAddressLineText: aggregationFieldTemplate(),
      PropertyCityName: aggregationFieldTemplate(),
      PropertyStateCode: aggregationFieldTemplate(),
      PropertyPostalCode: aggregationFieldTemplate(),
      PropertyType: aggregationFieldTemplate(),
      PropertyId: aggregationFieldTemplate(),
      OriginalPurchaseDate: aggregationFieldTemplate(),
      BorrowerTier: aggregationFieldTemplate()
    }
  };
  return template;
};

export const getLimaSchemaDealTemplate = (): LimaDealFields => {
  const baseDeal = omit(getBaseSchemaDealTemplate(), limaDealBaseFieldsToOmit);
  const limaDealFields: LimaDealFields = {
    AllongeAssignmentSentToWarehouseIndicator: "" as BooleanSelectType,
    AllongeAssignmentTrackingNumber: "",
    AmortizationType: "" as AmortizationType,
    ApplicationRequestedLoanAmount: "",
    AppraisalNotes: "",
    AssociatedPropertiesDescription: "",
    Broker: {
      BrokerWiringInstructionsIndicator: "" as BooleanSelectType,
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      FullName: "",
      NMLSIdentifier: "",
      RepresentativeFirstName: "",
      RepresentativeLastName: ""
    },
    BuildingEnvelopeExpandedIndicator: "" as BooleanSelectType,
    BuildingPermitsExpectedDate: "",
    BuildingPermitsIndicator: "" as BooleanSelectType,
    Channel: "" as OptionsOf<typeof limaChannelType>,
    ChargentCollectionAmount: "",
    ChargentGatewayIdentifier: "",
    ChargentTransactionIdentifier: "",
    ConstructionBudgetRemainingAmount: "",
    ConstructionBudgetVerifiedMatchIndicator: "" as BooleanSelectType,
    CreditReviewCommittee: {
      creditMemoApprovedDate: "",
      creditMemoDraftedDate: "",
      externalApprovalDate: "",
      externalSubmissionDate: "",
      internalApprovalDate: "",
      internalSubmissionDate: "",
      lOIAcceptedDate: "",
      lOIIssuedDate: ""
    },
    CRMId: "",
    CurrentLotMarketValueAmount: "",
    DeferredMaintenanceAmount: "",
    DevelopmentStrategy: "",
    EmployeeLoanIndicator: "" as BooleanSelectType,
    EONamedInsuredMatchIndicator: "" as BooleanSelectType,
    Exceptions: {
      ExceptionApprovedBy: "" as OptionsOf<typeof limaDealExceptionApprovedBy>,
      PolicySection: []
    },
    ExecutionType: "" as ExecutionType,
    ExitStrategy: "",
    FloodInsuranceAgency: {
      FullName: ""
    },
    FloodInsurancePremiumPaymentType: "" as OptionsOf<
      typeof limaFloodInsurancePremiumPayment
    >,
    FundingAndDisbursementApprovalIndicator: "" as BooleanSelectType,
    FundingShieldStatusType: "" as FundingShieldStatusType,
    GeneralContractor: {
      CapitalExpenditureAmount: "",
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      FullName: "",
      RepresentativeFirstName: "",
      RepresentativeLastName: ""
    },
    GrossSpreadPercent: "",
    HOAContactEmail: "",
    HOAContactName: "",
    HOAContactPhone: "",
    InitialLTVRatePercent: "",
    Integrations: {
      WireInsuranceCertificate: {
        FundingShield: getFundingShieldWireInsuranceCertTempate()
      }
    },
    InterestReserveEscrowOverrideAmount: "",
    InterestReserveEscrowOverrideMonthCount: "",
    LastPaymentDueDate: "",
    LenderFinance: {
      BorrowerFinalTermSheetApprovalStatusDate: "",
      PartnerFinalTermSheetApprovalStatusDate: "",
      PartnerFirstName: "",
      PartnerInitialTermSheetApprovalStatusDate: "",
      PartnerInitialTermSheetApprovalStatusIndicator: "" as BooleanSelectType,
      PartnerLastName: ""
    },
    LenderIdentifier: "" as OptionsOf<typeof limaLenderIdentifierType>,
    LenderPropertyInsuranceProviderIndicator: "" as BooleanSelectType,
    LenderTitleProviderIndicator: "" as BooleanSelectType,
    LetterOfIntentSignedDate: "",
    LienDollarAmount: "",
    LoadBearingWallsRemoveIntendedIndicator: "" as BooleanSelectType,
    LoanAfterInterestOnlyPeriodMonthCount: "",
    LoanAmountVerifiedMatchIndicator: "" as BooleanSelectType,
    LoanPackageCheckAmount: "",
    LoanPackageCheckNumber: "",
    LoanPackageChecksReceivedIndicator: "" as BooleanSelectType,
    LoanPaymentType: "" as OptionsOf<typeof limaLoanPayment>,
    LoanTermPeriodMonthCount: "" as OptionsOf<
      typeof limaLoanTermPeriodMonthCount
    >,
    LotStatus: "",
    LotUtilitiesIndicator: "",
    LotZonedParcelIdIndicator: "",
    Occupancy: "",
    OccupationIndicator: "",
    PaymentDueDay: "" as OptionsOf<typeof limaPaymentDueDay>,
    PersonalGuarantorsCount: "",
    PostClosingNotes: "",
    PowerOfAttorneyFullName: "",
    ProcessingFeeOverride: "",
    ProgramDirectorApprovalIndicator: "" as BooleanSelectType,
    ProgramDirectorComments: "",
    ProjectAnticipatedCompletionTimeline: "",
    PromissoryNoteRequestedIndicator: "" as BooleanSelectType,
    PromissoryNoteShippedIndicator: "" as BooleanSelectType,
    PromissoryNoteTrackingNumber: "",
    PropertyInsuranceAgency: {
      FullName: ""
    },
    PropertyLeasePurchaseOptionIndicator: "",
    PropertyManagement: {
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      FullAddressName: "",
      FullName: "",
      PropertyManagementComments: "",
      PropertyManagerType: "" as PropertyManagerType,
      RepresentativeFullName: ""
    },
    PropertyRentReadyConditionIndicator: "",
    PropertyTypeChangingIndicator: "" as BooleanSelectType,
    PropertyZoningChangesIndicator: "" as BooleanSelectType,
    quote: getDealQuoteTemplate(),
    RecourseType: "" as RecourseType,
    RecordedMortgageReceivedDate: "",
    RecordedWarrantyDeedReceivedDate: "",
    ReleasePercent: "",
    SellerRelationshipIndicator: "",
    SettlementStatementMatchLedgerIndicator: "" as BooleanSelectType,
    SquareFootageAddedIndicator: "" as BooleanSelectType,
    SubdividePropertyPartialReleasesIndicator: "",
    TargetCloseDate: "",
    TitleCommitment: {
      AllPayoffsLiensIncludedInSection1RequirementsIndicator:
        "" as BooleanSelectType,
      LeaseholdEndorsementIndicator: "" as BooleanSelectType,
      RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator:
        "" as BooleanSelectType
    },
    TitlePolicyReceivedDate: "",
    ValuationFeesCollectedBySalesIndicator: "" as BooleanSelectType,
    VerifiableConstructionCompletedAmount: "",
    WarehouseBankType: "" as OptionsOf<typeof limaWarehouseBank>,
    WireInsuranceMatchDealIndicator: "",
    aggregations: {
      ARMRepriceFrequencyType: aggregationFieldTemplate(),
      Integrations: {
        WireInsuranceCertificate: {
          FundingShield: getFundingShieldWireInsuranceCertAggregationTempate()
        }
      },
      MonthlyPaymentOfCapitalExpenditure: aggregationFieldTemplate(),
      TotalAnnualCapitalExpenditure: aggregationFieldTemplate(),
      TotalBrokerFeeAmount: aggregationFieldTemplate(),
      EstimatedClosingDate: thirdPartyFieldTemplate(),
      LoanProgramType: thirdPartyFieldTemplate()
    }
  };

  return merge(baseDeal, limaDealFields);
};
