export enum StatsigEnv {
  Localhost = "localhost",
  ElphiTest = "elphi-test",
  ElphiSandbox = "elphi-sandbox",
  Demo = "demo",
  LimaTest = "lima-test",
  LimaPoc = "lima-poc",
  LimaProd = "lima-prod",
  MofinTest = "mofin-test",
  MofinProd = "mofin-prod",
  LedgerTCTest = "ledgertc-test",
  LedgerTCProd = "ledgertc-prod",
  L1cTest = "l1c-test"
}
