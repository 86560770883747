import { Box, BoxProps, Button } from "@chakra-ui/react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { useInsurancePolicyModalsHooks } from "./insurancePolicyModal.hooks";
import { PropertyInsurancePolicyModal } from "./property/PropertyInsurancePolicyModal";

export const CreateInsurancePolicyButton = (props: {
  dealId: string;
  buttonWrapper?: BoxProps;
  onPolicyCreated?: (isCreationProcess: boolean) => void;
}) => {
  const {
    isInsurancePolicyCreateModalOpen,
    onToggleInsuranceCreatePolicyModal
  } = useInsurancePolicyModalsHooks();

  const { buttonWrapper = {} } = props;
  return (
    <>
      <Box {...buttonWrapper}>
        <Button
          {...elphiTheme.components.light.button.primary}
          onClick={onToggleInsuranceCreatePolicyModal}
        >
          Add Insurance Policy
        </Button>
      </Box>
      <PropertyInsurancePolicyModal
        showDomainStep={true}
        isOpen={isInsurancePolicyCreateModalOpen}
        onClose={onToggleInsuranceCreatePolicyModal}
        dealId={props.dealId}
        onPolicyCreated={props.onPolicyCreated}
      />
    </>
  );
};
