import { merge, omit } from "lodash";
import {
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../../../../../types/aggregations";
import { BaseSchemaProperty } from "../../../../../../types/entities/base";
import { OptionsOf } from "../../../../../../types/entities/enumeration.types";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  LimaProperty,
  LimaPropertyFields,
  limaAppraisalType,
  limaConditionRating,
  limaConstructionBudgetAssessment,
  limaLeaseStatus,
  limaPropertyType
} from "../../../../../../types/entities/lima";
import {
  StateCode,
  StateName
} from "../../../../../../types/entities/location.types";
import {
  AppraisalCoordinatorType,
  FloodInsurancePremiumPaymentType,
  PropertyInsurancePremiumPaymentType,
  QualityRatingType
} from "../../../../../../types/entities/property.enums.types";
import { DotNestedKeys } from "../../../../../../types/utils/flatten";
import {
  getPropertyReportSitexAggregationTempate,
  getRentalDataRentRangeAggregationTempate,
  getServiceLinkFloodCertAggregationTempate,
  getServiceLinkFloodCertTempate
} from "../../integrations/property";
import { getBaseSchemaPropertyTemplate } from "../base-schema";
import { BaseFields } from "../types";

const limaPropertyBaseFieldsToOmit: DotNestedKeys<BaseSchemaProperty>[] = [
  "PropertyType",
  "LeaseStatusType",
  "ConstructionBudgetAssessment",
  "Appraisal.AppraisalType",
  "Appraisal.ConditionRatingType",
  "LeasedFinancedUnits.unit0.LeaseStatusType",
  "LeasedFinancedUnits.unit1.LeaseStatusType",
  "LeasedFinancedUnits.unit2.LeaseStatusType",
  "LeasedFinancedUnits.unit3.LeaseStatusType",
  "LeasedFinancedUnits.unit4.LeaseStatusType",
  "LeasedFinancedUnits.unit5.LeaseStatusType",
  "LeasedFinancedUnits.unit6.LeaseStatusType",
  "LeasedFinancedUnits.unit7.LeaseStatusType",
  "LeasedFinancedUnits.unit8.LeaseStatusType",
  "AllocatedLoanAmount",
  "ConstructionHoldbackAmount",
  "GroundLeaseExpirationDate",
  "ClosingCostsAmount",
  "aggregations.CostBasisAmount",
  "aggregations.LoanToValueRatePercent",
  "aggregations.AsIsLoanToValueRatePercent",
  "aggregations.LTARVRatePercent",
  "aggregations.LTTCRatePercent",
  "aggregations.MonthlyTaxEscrowAmount",
  "aggregations.MonthlyInsuranceEscrowAmount",
  "aggregations.MonthlyEscrowPaymentsAmount",
  "aggregations.ConstructionBudgetHoldbackPercent",
  "aggregations.PayoffAmountOnSettlementStatement",
  "aggregations.ProjectROC",
  "InitialFundedAmount",
  "CityMonthlyTaxAmount",
  "CountyMonthlyTaxAmount",
  "SpecialAssessmentMonthlyTaxAmount",
  "FloodInsurance.MonthlyInsurancePremiumAmount",
  "PropertyInsurance.MonthlyInsurancePremiumAmount",
  "PayoffInterestDays"
];

const LeasedFinancedUnitTemplate = () => ({
  CorporateLeaseIndicator: "" as BooleanSelectType,
  LeaseStatusType: "" as OptionsOf<typeof limaLeaseStatus>,
  PaymentVerificationIndicator: "" as BooleanSelectType
});
export const getLimaSchemaPropertyTemplate = (): Omit<
  LimaProperty,
  BaseFields
> => {
  const baseDeal = omit(
    getBaseSchemaPropertyTemplate(),
    limaPropertyBaseFieldsToOmit
  );

  const limaPropertyFields: LimaPropertyFields = {
    AnnualCapitalExpenditure: "",
    Appraisal: {
      AppraisalCoordinator: "" as AppraisalCoordinatorType,
      AppraisalType: "" as OptionsOf<typeof limaAppraisalType>,
      ConditionRatingType: "" as OptionsOf<typeof limaConditionRating>,
      InternalValuationEligibilityIndicator: "" as BooleanSelectType,
      QualityRatingType: "" as QualityRatingType
    },
    CEMARefinanceIndicator: "" as BooleanSelectType,
    CondoCertificateComments: "",
    CondoCertificateFeesCollectedIndicator: "" as BooleanSelectType,
    ConstructionBudgetAssessment: "" as OptionsOf<
      typeof limaConstructionBudgetAssessment
    >,
    ContractExecutionDate: "",
    defaultInterestPaymentsOrFeesNotedIndicator: "",
    FloodInsurance: {
      DeductibleAmount: "",
      DwellingCoverageAmount: "",
      InsurancePremiumPaymentType: "" as FloodInsurancePremiumPaymentType,
      PolicyExpirationDate: "",
      PolicyNumber: ""
    },
    FloodInsuranceAgency: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      CityName: "",
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      CountyName: "",
      FullName: "",
      RepresentativeFullName: "",
      PostalCode: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName
    },
    InternalL1CRefinanceIndicator: "" as BooleanSelectType,
    LeaseStatusType: "" as OptionsOf<typeof limaLeaseStatus>,
    MortgageeContactFullName: "",
    MortgageeContactPointEmailValue: "",
    MortgageeContactPointTelephoneValue: "",
    MortgageeFullName: "",
    PropertyDetailComments: "",
    PropertyInsurance: {
      DeductibleAmount: "",
      DwellingCoverageAmount: "",
      LiabilityCoverageAmount: "",
      PolicyExpirationDate: "",
      PropertyInsurancePolicyIdentifier: "",
      RentalLossCoverageAmount: "",
      InsurancePremiumPaymentType: "" as PropertyInsurancePremiumPaymentType,
      ReplacementCoverageIndicator: "" as BooleanSelectType,
      BuilderRiskInsurance: ""
    },
    PropertyType: "" as OptionsOf<typeof limaPropertyType>,
    PropertyInsuranceAgency: {
      AddressLineText: "",
      AddressUnitIdentifier: "",
      CityName: "",
      ContactPointEmailValue: "",
      ContactPointTelephoneValue: "",
      CountyName: "",
      FullName: "",
      InsuranceAgentFullName: "",
      PostalCode: "",
      StateCode: "" as StateCode,
      StateName: "" as StateName
    },
    PSAAddendumIndicator: "" as BooleanSelectType,
    PSABuyerBorrowerMismatchIndicator: "" as BooleanSelectType,
    PSAExtensionFormIndicator: "" as BooleanSelectType,
    PSASellerOwnerRecordMismatchIndicator: "" as BooleanSelectType,
    RentalLTVPercent: "",
    LeasedFinancedUnits: {
      unit0: LeasedFinancedUnitTemplate(),
      unit1: LeasedFinancedUnitTemplate(),
      unit2: LeasedFinancedUnitTemplate(),
      unit3: LeasedFinancedUnitTemplate(),
      unit4: LeasedFinancedUnitTemplate(),
      unit5: LeasedFinancedUnitTemplate(),
      unit6: LeasedFinancedUnitTemplate(),
      unit7: LeasedFinancedUnitTemplate(),
      unit8: LeasedFinancedUnitTemplate()
    },
    aggregations: {
      Integrations: {
        FloodCertification: {
          ServiceLink: getServiceLinkFloodCertAggregationTempate()
        },
        PropertyReport: {
          SiteX: getPropertyReportSitexAggregationTempate()
        },
        RentalData: {
          RentRange: getRentalDataRentRangeAggregationTempate()
        }
      },
      BridgeLTVPercent: thirdPartyFieldTemplate(),
      PricingEngineLTARVRatePercent: aggregationFieldTemplate(),
      AllocatedLoanAmount: aggregationFieldTemplate(),
      EstimatedDelayedPurchaseProceeds: aggregationFieldTemplate()
    },
    Integrations: {
      FloodCertification: {
        ServiceLink: getServiceLinkFloodCertTempate()
      }
    }
  };

  const limaProperty = merge(baseDeal, limaPropertyFields);
  return limaProperty as Omit<LimaProperty, BaseFields>;
};
