import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Progress,
  Text,
  Tooltip,
  useCallbackRef
} from "@chakra-ui/react";
import { AggregationType, Asset, Party, Statement } from "@elphi/types";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import lodash from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { AppConfig } from "../../config/appConfig";
import { auth } from "../../firebase/firebaseConfig";
import { getSpecs } from "../../forms/schemas/factories/specsFactory";
import useAssetHooks from "../../hooks/asset.hooks";
import { usePartyHooks } from "../../hooks/party.hooks";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import useStatementHooks from "../../hooks/statement.hooks";
import { RootState } from "../../redux/store";
import { AssetSliceState } from "../../redux/v2/asset";
import { PartyAssetSliceState } from "../../redux/v2/party-asset-relation";
import { StatementSliceState } from "../../redux/v2/statement";
import { getListFromDictionary } from "../../utils/batchUtils";
import AggregationFooter, {
  AggregationColumnTypes
} from "../application/AggregationFooter";
import { CreateStatementModal } from "../asset/CreateStatementComponent";
import { DeleteStatementModal } from "../asset/DeleteStatementComponent";
import { StatementHistoryModalContainer } from "../asset/StatementHistoryComponent";
import {
  ButtonIconDelete,
  ButtonIconRepeatClock
} from "../button-icon/ButtonIcon";
import { BorrowingEntity } from "../deal/utils/table.utils";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { getFocusedAggregationSpecs } from "../form-builder/field-specs/utils/aggregation.utils";
import { ElphiTable } from "../table/ElphiTableComponent";
import { ElphiCellType, ElphiTableProps } from "../table/table.types";
import { useElphiToast } from "../toast/toast.hook";
import { TableInput } from "../utils/tableUtils";
import { usePartyTableHook } from "./hooks/partyTable.hook";

export const AssetTableContainer = (props: {
  selectedParty: Partial<Party>;
  snapshotId?: string;
}) => {
  return props.snapshotId ? (
    <AssetTableSnapshotContainer {...props} snapshotId={props.snapshotId} />
  ) : (
    <AssetTableLiveStateContainer {...props} />
  );
};
const AssetTableSnapshotContainer = (props: {
  selectedParty: Partial<Party>;
  snapshotId: string;
}) => {
  const { snapshotDataState } = useSnapshotHooks();
  const snapshot = snapshotDataState({
    snapshotId: props.snapshotId
  });
  if (
    !snapshot.partyState ||
    !snapshot.assetState ||
    !snapshot.partyAssetState ||
    !snapshot.statementState
  ) {
    return <>empty asset snapshot</>;
  }
  return (
    <AssetTable
      snapshotId={props.snapshotId}
      isDisabled={!!props.snapshotId}
      selectedParty={props.selectedParty}
      partyState={snapshot.partyState}
      assetState={snapshot.assetState}
      partyAssetState={snapshot.partyAssetState}
      statementState={snapshot.statementState}
      isLoading={false}
    />
  );
};
const AssetTableLiveStateContainer = (props: {
  selectedParty: Partial<Party>;
}) => {
  const partyState = useSelector((state: RootState) => state.party);
  const assetState = useSelector((state: RootState) => state.asset);
  const partyAssetState = useSelector((state: RootState) => state.partyAsset);
  const statementState = useSelector((state: RootState) => state.statement);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedParty } = props;
  const { getPartyAssetBatch, getPartyAssetBatchResponse } = usePartyHooks();
  const {
    updateBatch: updateAssetBatch,
    getLatestStatements,
    getLatestStatementsResponse,
    getBatchAsssetParty,
    getBatchAssetPartyResponse
  } = useAssetHooks();
  const { handleUpdateStatementBatch } = useStatementHooks();
  const { errorToast, successToast } = useElphiToast();
  const { handleUpdatePartyBatch } = usePartyTableHook();

  useEffect(() => {
    if (
      getPartyAssetBatchResponse.isUninitialized &&
      !getPartyAssetBatchResponse.isSuccess &&
      !getPartyAssetBatchResponse.isLoading &&
      !getPartyAssetBatchResponse.isFetching &&
      !getLatestStatementsResponse.isSuccess &&
      !getLatestStatementsResponse.isLoading &&
      !getLatestStatementsResponse.isFetching &&
      !getBatchAssetPartyResponse.isSuccess &&
      !getBatchAssetPartyResponse.isLoading &&
      !getBatchAssetPartyResponse.isFetching &&
      auth.currentUser
    ) {
      setIsLoading(true);
    } else setIsLoading(false);
  }, [auth.currentUser, getPartyAssetBatchResponse]);

  useEffect(() => {
    if (selectedParty && selectedParty.id) {
      getPartyAssetBatch([selectedParty.id]).then((res) => {
        if (res.status === 200) {
          const assetIds = Object.keys(res?.data?.assets.entities || {}) || [];
          getLatestStatements({ assetIds });
          getBatchAsssetParty(assetIds);
        }
      });
    }
  }, [selectedParty]);
  const handleUpdateAssetBatch = useCallbackRef(
    async (r: { assets: { [id: string]: Asset } }) => {
      if (!r.assets) return null;
      const listAssets = getListFromDictionary(r?.assets);
      if (!listAssets.length) return null;
      const filteredAssets = listAssets.filter((d): d is Asset => !!d);
      return await updateAssetBatch({ assets: filteredAssets }).then((r) => {
        if (r.status === 200) {
          successToast({
            title: "Assets Updated",
            description: `total assets updated: ${listAssets.length}`
          });
        }
        r.status === 400 &&
          errorToast({
            title: "Failed to update batch",
            description: r.data.description
          });
        return r;
      });
    }
  );

  const handleUpdatePartyBatchCallback = useCallbackRef(
    async (r: { parties: { [id: string]: Party } }) => {
      handleUpdatePartyBatch(r);
    }
  );

  return (
    <AssetTable
      handleUpdateStatementBatch={handleUpdateStatementBatch}
      handleUpdateAssetBatch={handleUpdateAssetBatch}
      handleUpdatePartyBatch={handleUpdatePartyBatchCallback}
      getLatestStatements={getLatestStatements}
      selectedParty={selectedParty}
      partyState={partyState}
      assetState={assetState}
      partyAssetState={partyAssetState}
      statementState={statementState}
      isLoading={isLoading}
    />
  );
};
const AssetTable = (props: {
  snapshotId?: string;
  isDisabled?: boolean;
  selectedParty: Partial<Party>;
  partyState: EntityState<Party>;
  assetState: AssetSliceState;
  partyAssetState: PartyAssetSliceState;
  statementState: StatementSliceState;
  isLoading: boolean;
  handleUpdateAssetBatch?: any;
  handleUpdateStatementBatch?: ReturnType<
    typeof useStatementHooks
  >["handleUpdateStatementBatch"];
  handleUpdatePartyBatch?: any;
  getLatestStatements?: ReturnType<typeof useAssetHooks>["getLatestStatements"];
}) => {
  const {
    partyState,
    assetState,
    partyAssetState,
    statementState,
    selectedParty,
    isLoading,
    handleUpdateAssetBatch,
    handleUpdateStatementBatch,
    handleUpdatePartyBatch,
    getLatestStatements
  } = props;
  const PAGE_SIZE = 10;
  const [pageIndex, setPageIndex] = useState(1);
  const fieldsSpecs = getSpecs();
  const getAssetRowData = useCallback(
    (currentAssetId: EntityId) => {
      const latestStatement = lodash.orderBy(
        Object.values(statementState.entities).filter((s) => {
          return (s?.assetId || "") === currentAssetId;
        }),
        ["end", "createdAt._seconds"],
        ["desc", "desc"]
      )[0];
      return {
        parties: Object.values(partyState.entities).filter((party) => {
          const partyAssetId = `${currentAssetId}_${party?.id || ""}`;
          return !!partyAssetState.entities[partyAssetId];
        }),
        asset: assetState.entities[currentAssetId] as Asset,
        statement: latestStatement
      };
    },
    [partyState, assetState, statementState, partyAssetState]
  );

  const pageData = useMemo(() => {
    if (!selectedParty) return [];
    const currentAssets = assetState.ids.filter((assetId) => {
      return partyAssetState?.entities?.[`${assetId}_${selectedParty.id}`];
    });
    return currentAssets.map((id) => getAssetRowData(id));
  }, [selectedParty, assetState, partyAssetState, statementState, pageIndex]);

  const pageDataMemo = useMemo(() => {
    return pageData.slice((pageIndex - 1) * PAGE_SIZE, pageIndex * PAGE_SIZE);
  }, [pageIndex, pageData]);

  const {
    onChange: onAssetChange,
    state: localAssetState,
    syncState: syncAssetState
  } = useFormBuilderStateHandler({
    initialState: {
      assets: {}
    },
    callback: handleUpdateAssetBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const {
    onChange: onStatementChange,
    state: localStatementState,
    syncState: syncStatementState
  } = useFormBuilderStateHandler({
    initialState: {
      statements: {}
    },
    callback: handleUpdateStatementBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });
  const {
    onChange: onPartyChange,
    state: localPartyState,
    syncState: syncPartyState
  } = useFormBuilderStateHandler({
    initialState: {
      parties: {}
    },
    callback: handleUpdatePartyBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });
  useEffect(() => {
    if (assetState) {
      syncAssetState({
        shouldSync: !!assetState,
        state: assetState.entities,
        statePath: () => {
          return ["assets"];
        }
      });
    }
  }, [assetState]);

  useEffect(() => {
    if (statementState) {
      syncStatementState({
        shouldSync: !!statementState,
        state: statementState.entities,
        statePath: () => {
          return ["statements"];
        }
      });
    }
  }, [statementState]);

  useEffect(() => {
    if (partyState) {
      syncPartyState({
        shouldSync: !!partyState,
        state: partyState.entities,
        statePath: () => {
          return ["parties"];
        }
      });
    }
  }, [partyState]);

  const assetsSpecs = fieldsSpecs?.asset?.entitySpecs;
  const statementSpecs = fieldsSpecs?.statement?.entitySpecs;
  const {
    fullNameOnAccount,
    accountName,
    accountType,
    financialInstitution,
    last4Digits
  } = assetsSpecs || {};

  const assetTableFullDataRows: ElphiTableProps["rows"] = useMemo(() => {
    return (
      pageDataMemo?.flatMap((rowData, i) => {
        if (rowData.statement) {
          return {
            cells: [
              {
                index: 0,
                data: (
                  <TableInput
                    isDisabled={props.isDisabled}
                    specFields={fullNameOnAccount}
                    prefix={["assets", rowData.asset.id]}
                    state={localAssetState}
                    currentEntity={rowData.asset}
                    onChange={onAssetChange}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 1,
                data: (
                  <BorrowingEntity
                    currentParties={rowData.parties}
                    includeIndividuals={true}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 2,
                data: (
                  <TableInput
                    isDisabled={props.isDisabled}
                    specFields={accountName}
                    prefix={["assets", rowData.asset.id]}
                    state={localAssetState}
                    currentEntity={rowData.asset}
                    onChange={onAssetChange}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 3,
                data: (
                  <TableInput
                    isDisabled={props.isDisabled}
                    specFields={accountType}
                    prefix={["assets", rowData.asset.id]}
                    state={localAssetState}
                    currentEntity={rowData.asset}
                    onChange={onAssetChange}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 4,
                data: (
                  <TableInput
                    isDisabled={props.isDisabled}
                    specFields={financialInstitution}
                    prefix={["assets", rowData.asset.id]}
                    state={localAssetState}
                    currentEntity={rowData.asset}
                    onChange={onAssetChange}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 5,
                data: (
                  <TableInput
                    isDisabled={props.isDisabled}
                    specFields={last4Digits}
                    prefix={["assets", rowData.asset.id]}
                    state={localAssetState}
                    currentEntity={rowData.asset}
                    onChange={onAssetChange}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 6,
                data: (
                  <TableInput
                    isDisabled={props.isDisabled}
                    specFields={statementSpecs?.start}
                    prefix={["statements", rowData.statement.id]}
                    state={localStatementState}
                    currentEntity={rowData.statement}
                    onChange={onStatementChange}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 7,
                data: (
                  <TableInput
                    isDisabled={props.isDisabled}
                    specFields={statementSpecs?.end}
                    prefix={["statements", rowData.statement.id]}
                    state={localStatementState}
                    currentEntity={rowData.statement}
                    onChange={onStatementChange}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 8,
                data: (
                  <TableInput
                    isDisabled={props.isDisabled}
                    specFields={statementSpecs?.statementBalance}
                    prefix={["statements", rowData.statement.id]}
                    state={localStatementState}
                    currentEntity={rowData.statement}
                    onChange={onStatementChange}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 9,
                data: (
                  <TableInput
                    isDisabled={props.isDisabled}
                    specFields={statementSpecs?.unverifiedDeposits}
                    prefix={["statements", rowData.statement.id]}
                    state={localStatementState}
                    currentEntity={rowData.statement}
                    onChange={onStatementChange}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 10,
                data: (
                  <TableInput
                    isDisabled={props.isDisabled}
                    specFields={statementSpecs?.percentConsidered}
                    prefix={["statements", rowData.statement.id]}
                    state={localStatementState}
                    currentEntity={rowData.statement}
                    onChange={onStatementChange}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 11,
                data: (
                  <TableInput
                    isDisabled={props.isDisabled}
                    isAggregation={AggregationType.Aggregation}
                    specFields={getFocusedAggregationSpecs({
                      spec: statementSpecs?.aggregations?.qualifyingBalance,
                      focused:
                        localStatementState.statements[
                          rowData?.statement?.id || ""
                        ]?.aggregations?.qualifyingBalance?.focused
                    })}
                    prefix={["statements", rowData.statement.id]}
                    state={localStatementState}
                    currentEntity={
                      localStatementState.statements[
                        rowData?.statement?.id || ""
                      ] as Statement
                    }
                    onChange={onStatementChange}
                  />
                ),
                type: ElphiCellType.Element,
                maxWidth: "350px",
                minWidth: "350px"
              },
              {
                index: 12,
                data: (
                  <HStack spacing="10px" justifyContent={"center"}>
                    <Tooltip label="Add a statement">
                      <Center>
                        <CreateStatementModal
                          selectedAsset={rowData.asset}
                          isDisabled={props.isDisabled}
                        />
                      </Center>
                    </Tooltip>
                    <Tooltip label="View statements history">
                      <Center>
                        <StatementHistoryModalContainer
                          selectedAsset={rowData.asset}
                          snapshotId={props.snapshotId}
                        />
                      </Center>
                    </Tooltip>
                    <Tooltip label="Delete statement">
                      <Center>
                        <DeleteStatementModal
                          selectedStatement={rowData.statement}
                          callback={(_) =>
                            getLatestStatements?.({
                              assetIds: [rowData.asset.id]
                            })
                          }
                          isDisabled={props.isDisabled}
                        />
                      </Center>
                    </Tooltip>
                  </HStack>
                ),
                type: ElphiCellType.Element,
                maxWidth: "450px",
                minWidth: "450px"
              }
            ],
            index: rowData.asset?.index || `${i}`,
            minHeight: "53px",
            maxHeight: "53px"
          };
        }
        return {
          cells: [
            {
              index: 0,
              data: (
                <TableInput
                  isDisabled={props.isDisabled}
                  specFields={fullNameOnAccount}
                  prefix={["assets", rowData.asset.id]}
                  state={localAssetState}
                  currentEntity={rowData.asset}
                  onChange={onAssetChange}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 1,
              data: (
                <BorrowingEntity
                  currentParties={rowData.parties}
                  includeIndividuals={true}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 2,
              data: (
                <TableInput
                  isDisabled={props.isDisabled}
                  specFields={accountName}
                  prefix={["assets", rowData.asset.id]}
                  state={localAssetState}
                  currentEntity={rowData.asset}
                  onChange={onAssetChange}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 3,
              data: (
                <TableInput
                  isDisabled={props.isDisabled}
                  specFields={accountType}
                  prefix={["assets", rowData.asset.id]}
                  state={localAssetState}
                  currentEntity={rowData.asset}
                  onChange={onAssetChange}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 4,
              data: (
                <TableInput
                  isDisabled={props.isDisabled}
                  specFields={financialInstitution}
                  prefix={["assets", rowData.asset.id]}
                  state={localAssetState}
                  currentEntity={rowData.asset}
                  onChange={onAssetChange}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 5,
              data: (
                <TableInput
                  isDisabled={props.isDisabled}
                  specFields={last4Digits}
                  prefix={["assets", rowData.asset.id]}
                  state={localAssetState}
                  currentEntity={rowData.asset}
                  onChange={onAssetChange}
                />
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 6,
              data: <Box />,

              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 7,
              data: <Box />,

              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 8,
              data: <Box />,

              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 9,
              data: <Box />,

              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 10,
              data: <Box />,

              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            },
            {
              index: 11,
              data: <Box />,
              type: ElphiCellType.Element,
              maxWidth: "450px",
              minWidth: "450px"
            },
            {
              index: 12,
              data: (
                <HStack spacing="10px" justifyContent={"center"}>
                  <Tooltip label="Add a statement">
                    <Center>
                      <CreateStatementModal
                        selectedAsset={rowData.asset}
                        isDisabled={props.isDisabled}
                      />
                    </Center>
                  </Tooltip>
                  <Tooltip label="View statements history">
                    <Center>
                      <ButtonIconRepeatClock isDisabled={props.isDisabled} />
                    </Center>
                  </Tooltip>
                  <Tooltip label="Delete statement">
                    <Center>
                      <ButtonIconDelete isDisabled={props.isDisabled} />
                    </Center>
                  </Tooltip>
                </HStack>
              ),
              type: ElphiCellType.Element,
              maxWidth: "350px",
              minWidth: "350px"
            }
          ],
          index: rowData.asset?.index || `${i}`,
          minHeight: "53px",
          maxHeight: "53px"
        };
      }) || []
    );
  }, [
    partyState,
    pageDataMemo,
    localAssetState,
    assetState,
    partyAssetState,
    statementState,
    localStatementState
  ]);

  const assetTableHeader: ElphiTableProps["header"] = useMemo(() => {
    return [
      {
        index: 0,
        data: "Name on Account",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 1,
        data: "Assigned Parties",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 2,
        data: "Account Name",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 3,
        data: "Account Type",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 4,
        data: "Financial Institution",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 5,
        data: "Last 4 Digits",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 6,
        data: "Statement Period Start Date",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 7,
        data: "Statement Period End Date",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 8,
        data: "Statement Balance",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 9,
        data: "Statement Unverified Deposits",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 10,
        data: "Statement % Considered",
        type: ElphiCellType.String,
        maxWidth: "350px",
        minWidth: "350px"
      },
      {
        index: 11,
        data: "Statement Qualifying Balance",
        type: ElphiCellType.String,
        maxWidth: "450px",
        minWidth: "450px"
      },
      {
        index: 12,
        data: "Actions",
        type: ElphiCellType.String,
        maxWidth: "200px",
        minWidth: "200px"
      }
    ];
  }, []);

  const assetTableProps: ElphiTableProps = useMemo(() => {
    return {
      header: assetTableHeader,
      rows: assetTableFullDataRows,
      footer: (
        <Box>
          {isLoading && <Progress size="xs" isIndeterminate />}

          <Flex w="100%" justifyContent={"flex-start"} mb="20px" mt="10px">
            <Box lineHeight={"40px"}>
              <Text fontSize={"16px"} fontWeight={"bold"}>
                Page: {pageIndex} /{" "}
                {Math.floor(pageData.length / PAGE_SIZE + 1)}
              </Text>
            </Box>
            <Box pl="20px">
              <Button
                w={"100px"}
                {...elphiTheme.components.light.button.primary}
                isDisabled={pageIndex <= 1}
                onClick={() => {
                  setPageIndex(pageIndex - 1);
                }}
              >
                Prev
              </Button>
            </Box>
            <Box pl="10px" mr={"auto"}>
              <Button
                w={"100px"}
                {...elphiTheme.components.light.button.primary}
                isDisabled={pageIndex * PAGE_SIZE >= pageData.length}
                onClick={() => {
                  setPageIndex(pageIndex + 1);
                }}
              >
                Next
              </Button>
            </Box>
          </Flex>
        </Box>
      ),
      aggregationFooter: (
        <AggregationFooter
          columns={[
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Empty
            },
            {
              columnType: AggregationColumnTypes.Aggregation,
              input: (
                <Box>
                  <Text>
                    {fieldsSpecs?.party?.entitySpecs?.aggregations
                      ?.TotalStatementQualifyingBalance?.calculated?.label ||
                      ""}
                  </Text>
                  <TableInput
                    isDisabled={props.isDisabled}
                    isAggregation={AggregationType.Aggregation}
                    specFields={getFocusedAggregationSpecs({
                      spec: fieldsSpecs?.party?.entitySpecs?.aggregations!
                        .TotalStatementQualifyingBalance,
                      focused:
                        localPartyState?.parties?.[selectedParty?.id || ""]
                          ?.aggregations?.TotalStatementQualifyingBalance
                          ?.focused
                    })}
                    prefix={["parties", selectedParty?.id || ""]}
                    state={localPartyState}
                    currentEntity={
                      localPartyState.parties[selectedParty?.id || ""] as Party
                    }
                    onChange={onPartyChange}
                  />
                </Box>
              )
            },
            {
              columnType: AggregationColumnTypes.Empty
            }
          ]}
          width={"200px"}
        />
      ),
      isLoading: isLoading,
      rowsCount: 1
    };
  }, [
    assetTableFullDataRows,
    pageIndex,
    isLoading,
    selectedParty,
    localPartyState
  ]);

  return (
    <>
      <ElphiTable
        minHeight={"300px"}
        maxHeight={"825px"}
        header={assetTableProps.header}
        rows={assetTableProps.rows}
        footer={assetTableProps.footer}
        aggregationFooter={assetTableProps.aggregationFooter}
        isLoading={assetTableProps.isLoading}
        rowsCount={1}
      />
    </>
  );
};
