import {
  aggregationFieldTemplate,
  thirdPartyFieldTemplate
} from "../../../../../../types/aggregations";
import { BooleanSelectType } from "../../../../../../types/entities/fieldFormat.types";
import {
  AggregationServiceLinkFields,
  PropertyFloodCertificateFields
} from "../../../../../../types/entities/integrations";

export const getServiceLinkFloodCertAggregationTempate =
  (): AggregationServiceLinkFields => {
    return {
      OrderNumber: thirdPartyFieldTemplate(),
      OrderStatus: thirdPartyFieldTemplate(),
      FloodCheckStatus: thirdPartyFieldTemplate(),
      FloodCheckInfo: thirdPartyFieldTemplate(),
      FloodCheckDisclaimer: thirdPartyFieldTemplate(),
      FloodCheckStatusDescription: thirdPartyFieldTemplate(),
      Disclaimer: thirdPartyFieldTemplate(),
      DeterminationDate: thirdPartyFieldTemplate(),
      FloodMapDate: thirdPartyFieldTemplate(),
      FloodZone: thirdPartyFieldTemplate(),
      GeoCity: thirdPartyFieldTemplate(),
      GeoCounty: thirdPartyFieldTemplate(),
      GeoPropertyAddress1: thirdPartyFieldTemplate(),
      GeoStateCode: thirdPartyFieldTemplate(),
      GeoZipFirst5: thirdPartyFieldTemplate(),
      GeoZipLast4: thirdPartyFieldTemplate(),
      NFIPLegalCommunityNumber: thirdPartyFieldTemplate(),
      NFIPLegalCommunityName: thirdPartyFieldTemplate(),
      NFIPMapCommunityName: thirdPartyFieldTemplate(),
      NFIPMapCommunityNumber: thirdPartyFieldTemplate(),
      NFIPMapPanelNumber: thirdPartyFieldTemplate(),
      NFIPPrgStat: thirdPartyFieldTemplate(),
      PropertyMSAMD: thirdPartyFieldTemplate(),
      RequestedInformation: thirdPartyFieldTemplate(),
      ResultCode: thirdPartyFieldTemplate(),
      ResultDescription: thirdPartyFieldTemplate(),
      ReturnTime: thirdPartyFieldTemplate(),
      ReturnDate: thirdPartyFieldTemplate(),
      TSDNComments: thirdPartyFieldTemplate(),
      SpecialFloodHazardAreaIndicator: aggregationFieldTemplate()
    };
  };

export const getServiceLinkFloodCertTempate =
  (): PropertyFloodCertificateFields => {
    return {
      AcceptedAcknowledgmentIndicator: "" as BooleanSelectType,
      CTFFlagIndicator: "",
      FloodFlagIndicator: "",
      LOLFlagIndicator: "",
      ProductCode: "",
      UsersEmailAddress: ""
    };
  };
