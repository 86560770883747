import { thirdPartyFieldTemplate } from "../../../../../../types/aggregations";
import { AggregationSiteXFields } from "../../../../../../types/entities/integrations";

export const getPropertyReportSitexAggregationTempate =
  (): AggregationSiteXFields => {
    return {
      addressLineText: thirdPartyFieldTemplate(),
      addressUnitIdentifier: thirdPartyFieldTemplate(),
      cityName: thirdPartyFieldTemplate(),
      stateCode: thirdPartyFieldTemplate(),
      postalCode: thirdPartyFieldTemplate(),
      countyName: thirdPartyFieldTemplate(),
      assessorParcelNumber: thirdPartyFieldTemplate(),
      fips: thirdPartyFieldTemplate(),
      orderStatus: thirdPartyFieldTemplate(),
      orderCompletedOn: thirdPartyFieldTemplate()
    };
  };
